.section-store-locator {
  .store-locator__nav {
    .store-locator__nav-item.last {
      display: none !important;
    }
  }
  .store-locator--local-search {
    .local-search__search-container {
      .local-search-form__city-state-wrapper {
        span.selectBox-label {
          width: 117px;
          padding-right: 0;
        }
      }
    }
  }
}

.section-esearch {
  .search-product {
    .search-product__details {
      .search-product__quantity {
        width: 135px;
      }
      .btn-add-to-bag.js-add-to-cart {
        width: 180px;
        height: auto;
        line-height: normal;
        padding: 4px 10px;
      }
    }
  }
}

.page-wrapper {
  .spp-product__mini-bag.spp-product__mini-bag-show {
    .spp-product__mini-bag-add-button {
      font-size: 9px;
    }
  }
  .page-header {
    .page-utilities-loyalty-elc-nodeblock {
      float: left;
    }
    .page-utilities__account {
      @media #{$medium-up} {
        cursor: pointer;
        left: -30px;
      }
      @media #{$xlarge-up} {
        left: -10px;
      }
    }
  }
  .mpp {
    &__product {
      .product_brief {
        &__buttons {
          width: 195px;
          .product_brief__button--shop-now {
            width: 150px;
          }
        }
        &__quantity {
          width: 45px;
        }
        &__button--add-to-bag {
          width: 140px;
          font-size: 10px;
        }
      }
    }
    #mpp__filter-tab {
      display: none;
    }
  }
  .spp {
    .product-full {
      h4.product_brief__sub-header {
        margin-top: 2px;
        font-size: 29px;
        font-family: 'Optima Display Roman';
      }
    }
  }
  .ff-quiz__inner {
    .ff-quiz__slide--1 {
      .ff-quiz__headline {
        font-family: $optimalight !important;
      }
    }
  }
  .estee-edit-article-fragrance-glossary-page {
    .formatter-estee-edit {
      .ee_fragrance-dictionary-ex {
        font-family: $optimalight !important;
        letter-spacing: 0.02em;
      }
    }
  }
  select {
    -ms-appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }
  select::-ms-expand {
    display: none;
  }
  .formatter-estee-edit__content {
    .basic-textarea-v1 {
      .ee-first-letter {
        padding: 1px 6px 0 0;
      }
    }
  }
  .spp-product__details-attribute {
    p b {
      font-weight: normal;
    }
    ul {
      li {
        font-weight: normal;
        b {
          font-weight: normal;
        }
      }
    }
  }
}

.sd-product-grid
  .elc-grid-item-product
  .elc-product-quick-view-button-wrapper
  .elc-product-quick-view-button {
  padding: 0 10px;
}

#prechat_container {
  .prechat-form-container {
    margin-top: 0px;
    h4 {
      margin-top: 0px;
      font-family: $optimalight !important;
      font-size: 29px;
      padding-right: 25px;
    }
    .prechat-messaging-area {
      p {
        margin: 0 0 6px;
        line-height: normal;
      }
    }
  }
}

#colorbox {
  &.welcome15-overlay.alt_layout1 {
    .email_popover {
      &__social_icons {
        top: 345px;
      }
    }
  }
  #cboxLoadedContent {
    .bisPcIframe {
      height: 375px !important;
    }
  }
}

.embeddedServiceHelpButton {
  .helpButton {
    display: none;
  }
}

.product-full {
  .product-full__description-section {
    a.selectBox.product-full__quantity {
      width: 125px !important;
    }
  }
}

.selectBox-options li a {
  white-space: normal !important;
}

.estee-edit-article-bottle-service-page {
  .formatter-estee-edit__content {
    h2 {
      &.header-meta__subhead {
        font-size: 16px;
        @media #{$large-up} {
          font-size: 30px !important;
          letter-spacing: -0.045em;
        }
      }
      &.ee-disco-more__headline-main {
        font-size: 21px;
        @media #{$large-up} {
          font-size: 40px !important;
          letter-spacing: -0.045em;
        }
      }
    }
    h3 {
      &.ee--subhead {
        font-size: 25px;
        @media #{$large-up} {
          font-size: 28px !important;
          letter-spacing: -0.05em;
        }
      }
    }
    .ee-article-products h2 {
      font-size: 40px !important;
      letter-spacing: -0.045em;
    }
  }
}

.page-footer {
  .footer-forms-location-sms-email-signup-elc-nodeblock {
    .footer-signup-email {
      width: 50%;
      .email-signup {
        .email_signup {
          &__header {
            max-width: 524px;
          }
        }
        &__section {
          width: 55%;
        }
      }
    }
  }
}

.selectBox-options {
  li {
    div.filter-ligera_a_media {
      background: #ddb491;
      border-right: 8px solid #edbe98;
    }
    div.filter-media {
      background: #e5ad8a;
      border-right: 8px solid #d59a71;
    }
    div.filter-medio_a_obscuro {
      background: #b56e38;
      border-right: 8px solid #aa6533;
    }
  }
}

@media #{$medium-only} {
  .footer-legal {
    .menu li {
      padding-right: 10px;
    }
  }
}

#viewcart {
  #colorbox.colorbox__quickshop {
    .quickshop__image-container {
      .quickshop__image {
        img {
          width: 90%;
        }
      }
    }
    #cboxContent {
      height: 658px !important;
      min-height: 658px;
      #cboxLoadedContent {
        height: 658px !important;
        overflow-y: auto !important;
        &::-webkit-scrollbar {
          display: none;
        }
        scrollbar-width: none;
        .quickshop {
          min-height: unset;
        }
      }
    }
  }
}

/* Add tracking -50 to all class that using new font "Conv_OptimaEL-Text" */
h1,
h2,
h3 {
  letter-spacing: -0.05em;
}

h4 {
  letter-spacing: -0.03em;
}

.headline--page,
.headline--primary,
.headline--large {
  letter-spacing: -0.05em;
}

.headline--secondary,
.headline--medium,
.device-pc .discover-more__header {
  letter-spacing: -0.05em;
}

.headline--tertiary,
.section-store-locator.device-pc .store-locator .store-locator__title {
  letter-spacing: -0.05em;
}

.promo-unit__headline {
  font-family: $optimaregular;
  letter-spacing: -0.08em;
}

.promotions .promo-unit__headline {
  letter-spacing: -0.05em;
}

.headline--products,
.mpp__header {
  letter-spacing: -0.05em;
}

.headline--quaternary,
blockquote,
.pull-quote,
.loyalty_mrkt_ldng .loyalty_multi_image_tout {
  letter-spacing: -0.05em;
}

.anr-simple__header-subhead {
  letter-spacing: -0.05em;
}

.anr-simple__sec2plus-headline {
  letter-spacing: -0.05em;
  font-family: $optimalight !important;
}

.text-primary-headlines {
  letter-spacing: -0.05em;
}

.text-medium-headlines {
  letter-spacing: -0.07em;
}

.text-product-names-main,
.spp-product__header {
  letter-spacing: -0.05em;
}

.text-product-names-sub,
.spp-product__sub-header,
.spp-howtouse__header {
  letter-spacing: -0.05em;
}

.text-batch-2-primary-headline {
  letter-spacing: -0.05em;
}

.text-batch-2-medium-headline {
  letter-spacing: -0.05em;
}

.change-is-coming .cic-content .title1,
.change-is-coming .cic-content .title2 {
  letter-spacing: -0.05em;
}

.device-mobile .change-is-coming .cic-content .title1,
.device-mobile .change-is-coming .cic-content .title2 {
  letter-spacing: -0.1em;
}

.mpp__category-links--content {
  letter-spacing: -0.05em;
}

.chat-parallax__headline {
  letter-spacing: -0.07em;
}

.special-offer-row-promo-wrapper.special-offer-theme-regular .special-offer-headline {
  letter-spacing: -0.05em;
}

.special-offer-row-wrapper.special-offer-theme-regular .special-offer-headline {
  letter-spacing: -0.05em;
}

.special-offer-row-mobile.special-offer-theme-regular .special-offer-headline {
  letter-spacing: -0.05em;
}

.signup-tout-wrapper .signup-tout-content .headline1,
.signup-tout-wrapper .signup-tout-content .headline2 {
  letter-spacing: -0.05em;
}

.device-mobile .signup-tout-wrapper .signup-tout-content .headline1,
.device-mobile .signup-tout-wrapper .signup-tout-content .headline2 {
  letter-spacing: -0.1em;
}

.signup-tout-flexible-wrapper .signup-tout-content .headline1,
.signup-tout-flexible-wrapper .signup-tout-content .headline2 {
  letter-spacing: -0.1em;
}

.device-mobile .signup-tout-flxible-mobile-wrapper .signup-tout-content .headline1,
.device-mobile .signup-tout-flxible-mobile-wrapper .signup-tout-content .headline2 {
  letter-spacing: -0.1em;
}

.loyalty_market .loyalty_market__header {
  letter-spacing: -0.07em;
}

.loyalty_market .loyalty_market__tiers__header {
  letter-spacing: -0.07em;
}

.loyalty_market .loyalty_market__tier__header {
  letter-spacing: -0.07em;
}

.loyalty_market .loyalty_market__points__header {
  letter-spacing: -0.1em;
}

.r3__overlay .r3__overlay-header {
  letter-spacing: -0.05em;
}

.device-mobile #colorbox.welcome15-overlay.with-second-language .email_popover__content > h3,
.device-mobile #colorbox.welcome15-overlay.with-second-language .email_popover__content > h4 {
  letter-spacing: -0.05em;
}

.loyalty_popover__promo-header {
  letter-spacing: -0.07em;
}

.spp-discover-more-section-mobile .discover_more__product .discover_more__product-header {
  letter-spacing: -0.05em;
}

.spp-discover-more-section-mobile .discover_more__product .discover_more__product-sub-header {
  letter-spacing: -0.05em;
}

.wishlist-confirm__message {
  letter-spacing: -0.05em;
}

.spp-product__how-to-use__steps #spp-howtouse-steps-headline {
  letter-spacing: -0.001em;
}

.spp-product__how-to-use__steps .spp__steps .spp_howtouse_content .step-headline {
  letter-spacing: -0.05em;
}

.boutique-tout .boutique-tout-main-headline-wrapper .boutique-tout-main-headline {
  letter-spacing: -0.05em;
}

.boutique-tout.boutique-tout-size-small
  .boutique-tout-main-headline-wrapper
  .boutique-tout-main-headline {
  letter-spacing: -0.07em;
}

.ff-results .ff-results__count {
  letter-spacing: -0.07em;
}

.ff-results .ff-results__regimen-step-gutter {
  letter-spacing: -0.05em;
}

.section-store-locator.device-pc .store-locator .store-locator__title {
  letter-spacing: -0.05em;
}

.section-store-locator.device-pc .store-locator .intl-search .intl-search__label {
  letter-spacing: -0.06em;
}

.ee--subhead {
  letter-spacing: -0.05em;
}

.ee-first-letter {
  letter-spacing: -0.05em;
}

.formatter-estee-edit .header-meta__subhead {
  letter-spacing: -0.05em;
}

.ee-landing__filters__side__wrapper {
  letter-spacing: -0.05em;
}

.formatter-beauty-feed__header > .headline {
  letter-spacing: -0.07em;
}

.formatter-beauty-feed__footer > .headline {
  letter-spacing: -0.07em;
}

.beauty-feed-tout--tool__headline {
  letter-spacing: -0.05em;
}

.beauty-feed-tout--twitter .social-content {
  letter-spacing: -0.07em;
}

.sidebar-chat .sidebar-chat__header {
  letter-spacing: -0.05em;
}

.review-submit__product-head {
  letter-spacing: -0.05em;
}

.checkout-page-title {
  letter-spacing: -0.05em;
}

.samples-page .samples-panel__title {
  letter-spacing: -0.04em;
}

.confirmation-panel h3 {
  letter-spacing: -0.05em;
}

.checkout__header-item-count {
  letter-spacing: -0.05em;
}

#loyalty__panel__benefits .loyalty__panel__benefits__title,
#loyalty__panel__how-to-earn .loyalty__panel__how-to-earn__title,
#loyalty__panel__leave .loyalty__panel__leave__title,
#loyalty__panel__offers .loyalty__panel__offers__title,
#loyalty__panel__points .loyalty__panel__points__title,
#loyalty__panel__transactions .loyalty__panel__transactions__title,
#loyalty__panel__benefits .loyalty__panel__benefits__tier-level,
#loyalty__panel__offers__sku .loyalty__panel__offers__sku__title {
  letter-spacing: -0.04em;
}

#loyalty__content .e-list_not_signed .sub_title {
  letter-spacing: -0.045em;
}

#loyalty__content .points__current {
  letter-spacing: -0.05em;
}

#loyalty__panel__receipt .loyalty__panel__receipt__submit__method__title {
  letter-spacing: -0.07em;
}

.el-search-block input[type='text'].form-text {
  letter-spacing: -0.05em;
}

.el-search-block__links a {
  letter-spacing: -0.06em;
}

.search-page__headline {
  letter-spacing: -0.05em;
}

input[type='text'].search-term {
  letter-spacing: -0.07em;
}

#photo-upload-query div {
  letter-spacing: -0.05em;
}

.beauty-consultations {
  letter-spacing: -0.05em;
}

.up-next__label {
  letter-spacing: -0.05em;
}

.order_arrival_details .title {
  letter-spacing: -0.05em;
}

.ff-quiz__inner .ff-quiz__edit-headline {
  letter-spacing: -0.04em;
}

.ff-quiz__inner .ff-quiz__headline {
  letter-spacing: -0.07em;
}

.ff-quiz__inner .ff-quiz__headline--secondary {
  letter-spacing: -0.07em;
}

.ff-quiz__inner .ff-quiz__coverage-image,
.ff-quiz__inner .ff-quiz__intensity-image {
  letter-spacing: -0.1em;
}

.page-wrapper .spp .product-full h4.product_brief__sub-header {
  letter-spacing: -0.05em;
}

.page-wrapper .ff-quiz__inner .ff-quiz__slide--1 .ff-quiz__headline {
  letter-spacing: -0.05em;
}

.page-wrapper
  .estee-edit-article-fragrance-glossary-page
  .formatter-estee-edit
  .ee_fragrance-dictionary-ex {
  letter-spacing: -0.03em;
}

#prechat_container .prechat-form-container h4 {
  letter-spacing: -0.05em;
}

.anr-simple__sec2plus-headline {
  letter-spacing: -0.1em;
}

.headline--section {
  font-family: $optimaregular;
  letter-spacing: -0.01em;
}

.spp-product__details .spp-product__details-header {
  letter-spacing: -0.05em;
}

@media only screen and (min-width: 737px) {
  .headline--section {
    .headline--products,
    .mpp__header {
      letter-spacing: -0.07em;
    }
  }
  .header__headline.headline--small,
  .header__headline.headline--small2,
  .header__headline.headline--small3 {
    letter-spacing: -0.07em;
  }
  .device-mobile .header__headline.headline--small,
  .device-mobile .header__headline.headline--small2,
  .device-mobile .header__headline.headline--small3 {
    letter-spacing: -0.07em;
  }
  .sign-in-component .sign-in-component__header {
    font-family: $optimaregular;
    letter-spacing: -0.07em;
  }
  .sign-in-component .sign-in-component__confirm--registration .sign-in-component__header {
    letter-spacing: -0.05em;
  }
  .ee-landing__filters {
    letter-spacing: -0.05em;
  }
  .ee-landing__filters .mobile-trigger {
    letter-spacing: -0.05em;
  }
  .ee-landing__filters li {
    letter-spacing: -0.05em;
  }
  .need-help-panel .need-help-panel__title {
    letter-spacing: -0.05em;
  }
  #loyalty__panel__offers .loyalty__panel__offers__title {
    letter-spacing: -0.04em;
  }
  #loyalty__content .e-list_not_signed .title_main {
    letter-spacing: -0.05em;
  }
  .loyalty-account-page #loyalty__page__history .loyalty__panel__transactions__title {
    letter-spacing: -0.04em;
  }
}

.lookup_results_container {
  .address_lookup_wrapper {
    border: 1px solid $color-lighter-gray-alt;
    clear: both;
    padding: 10px;
    width: 100%;
    height: 200px;
    overflow: auto;
    margin-bottom: 15px;
    li {
      padding-bottom: 1em;
    }
  }
}

#colorbox.colorbox__quickshop,
#product_brief__quickshop__container {
  .quickshop__tab--how-to-use {
    display: block;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $optimalight;
}

.tabbed-products-block {
  &__header {
    .tabbed-products-font & {
      font-family: $optimalight !important;
    }
  }
}

.el-search-block {
  input[type='text'].form-text {
    font-family: $optimalight !important;
  }
  .alt-color-white.is-search & {
    top: 60px;
  }
}

#colorbox {
  &.colorbox__quickshop {
    .quickshop__description {
      padding: 38px 32px 10px 0;
    }
    .quickshop__image-container {
      width: 450px;
    }
    .quickshop__headers {
      max-height: none;
    }
  }
}
