.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.account-utilities {
  .account-utilities__header {
    margin-bottom: 17px;
  }
}

.sidebar-menu {
  .sidebar-menu__item {
    margin: 0 0 19px;
  }
}

.sidebar-chat {
  .sidebar-chat__header {
    font-size: 40px;
  }
}

.sidebar-page::before {
  max-width: 255px;
}

.sidebar-page__sidebar {
  max-width: 255px;
}

section.account-utilities {
  ul.sidebar-menu {
    li.sidebar-menu__item {
      a.account-utilities__link.active {
        text-decoration: none;
        color: gray;
      }
    }
  }
}

fieldset.registration-page__personal-info {
  .form-item.csrHide {
    margin-top: 25px;
    padding: 1px 2px 5px 15px;
  }
  .form-item--email {
    @media #{$medium-up} {
      clear: both;
      padding-#{$rdirection} : 20px; 
    }
  }
}
// .page-wrapper {
.page-footer {
  .page-sticky-footer__left {
    .node-elc-nodeblock:first-child {
      border-left: none;
      // padding: 0 20px;
    }
  }
}
// }
